import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import PageHeader from '~components/PageHeader'
import styled from '@emotion/styled'
import Tile from '~components/Tile'
import Section from '~components/Section'
import { mobile, tablet } from '~styles/global'

const Group = ({ data }) => {

	const { page } = data
	const allProducts = data.allSanityProduct?.nodes

	const [products, setProducts] = useState([])

	useEffect(() => {
		if (page.selectAllProducts)  {
			setProducts(allProducts)
		} else {
			setProducts(page.selectedProducts)
		}
	}, [page])

	return(
		<>
			<Seo 
				title={page?.title}
				metaTitle={page?.seo?.metaTitle}
				description={page?.seo?.metaDescription}
				image={page?.seo?.socialImage}
			/>
			<PageHeader content={page?.header} page={page}/>
			<DataViewer data={data} name="data" />
			<Products>
				{products.map(product => (
					<Product key={product?.title} content={product}/>
				))}
			</Products>

		</>
	)
}

const Products = styled(Section)`
	padding: var(--xxxxl) 0 0;
	${mobile}{
		padding: var(--xxl) 0 0;
	}
`
const Product = styled(Tile)`
	grid-column: span 4;
	margin-bottom: var(--xxxxl);
	${tablet}{
		grid-column: span 6;
	}
	${mobile}{
		grid-column: span 12;
		margin-bottom: var(--xxl);
	}
`

Group.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query GroupQuery($slug: String) {
		allSanityProduct(
			sort: {fields: datePublished, order: DESC}
			filter: {store: {status: {eq: "active"}, isDeleted: {eq: false}}}
			) {
			nodes {
				...productThumb
			}
		}
    page: sanityGroup(slug: {current: {eq: $slug}}) {
      title
			_type
			slug {
				current
			}
			header {
				...pageHeader
			}
			selectAllProducts
			selectedProducts {
				...productThumb
			}
			intro: _rawIntro(resolveReferences: {maxDepth: 5})
      seo{
				...seo
			}
    }
  }
`

export default Group